import './styles.css'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { MSDFTextGeometry, MSDFTextMaterial, uniforms } from "three-msdf-text-utils";
import * as THREE from 'three';
import fragment from './shaders/fragment.glsl'
import vertex from './shaders/vertex.glsl'
import fragment1 from './shaders/fragment1.glsl'
import vertex1 from './shaders/vertex1.glsl'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

import { DotScreenShader } from './customShaders.js';
import GUI from 'lil-gui';
import VirtualScroll from 'virtual-scroll'

import gsap from "gsap";


import  './newjs.js'
import './another.js'
import LocomotiveScroll from 'locomotive-scroll';
import 'remixicon/fonts/remixicon.css'




const locomotiveScroll = new LocomotiveScroll();

const mobile = (window.innerWidth < 768);
const tablet = (window.innerWidth < 1024 && window.innerWidth > 768);


export default class App {

    constructor() {
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        document.querySelector('.webgl-container').appendChild(this.renderer.domElement);
        this.time = 0;

        this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 10);
        this.camera.position.z = 1.9;
        this.scene = new THREE.Scene();

        // Assuming this.smallSphere is defined and is a THREE.SphereGeometry

        // Calculate the position of the top of the sphere


        this.group = new THREE.Group();
        this.scene.add(this.group);
        this.initPost();
        this.resizeAll()
        this.addMesh();
        this.mousemove();
        // this.addText();
        // this.settings1();
        this.tick();

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // this.controls.enableDamping = true;

        this.position = 0;

        this.scroller = new VirtualScroll()
        this.scroller.on(event => {
            // wrapper.style.transform = `translateY(${event.y}px)`;

            this.position = event.y / 2000
        })

        window.addEventListener('mousemove', (e) => {
            this.mousex = (e.clientX / window.innerWidth) * 2 - 1;
            this.mousey = -(e.clientY / window.innerHeight) * 2 + 1;



            gsap.to(this.camera.rotation, {
                x: this.mousey * .2,
                y: this.mousex * .2,
                duration: 1
            })


        })
    }

    resizeAll() {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.composer.setSize(window.innerWidth, window.innerHeight);
    }

    // addText() {

    //     const Socials = [
    //         'INSTAGRAM',
    //         'TWITTER',
    //         'LINKEDIN',
    //         'GITHUB',
    //         'DISCORD'
    //     ]
    //     let radius = this.smallSphere.radius;
    //     this.curve = new THREE.EllipseCurve(
    //         this.smallSphere.position.x / 4,  // ax, aX center
    //         this.smallSphere.position.y / 4,  // ay, aY center
    //         this.smallSphere.radius,  // xRadius (reduced by half)
    //         this.smallSphere.radius,  // yRadius (reduced by half)
    //         0,                            // aStartAngle
    //         2 * Math.PI,                  // aEndAngle
    //         true,                        // aClockwise (modified to true for infinite rotation)
    //        this.position                   // aRotation
    //     );



    //     // Create a path from the curve
    //     const path = new THREE.Path(this.curve.getPoints(50));

    //     this.material3 = new THREE.ShaderMaterial({
    //         side: THREE.DoubleSide,
    //         transparent: true,
    //         defines: {
    //             IS_SMALL: false,
    //         },
    //         extensions: {
    //             derivatives: true,
    //         },
    //         uniforms: {
    //             // Common
    //             ...uniforms.common,

    //             // Rendering
    //             ...uniforms.rendering,

    //             // Strokes
    //             ...uniforms.strokes,
    //         },
    //         vertexShader: `
    //         // Attribute
    //         attribute vec2 layoutUv;

    //         attribute float lineIndex;

    //         attribute float lineLettersTotal;
    //         attribute float lineLetterIndex;

    //         attribute float lineWordsTotal;
    //         attribute float lineWordIndex;

    //         attribute float wordIndex;

    //         attribute float letterIndex;

    //         // Varyings
    //         varying vec2 vUv;
    //         varying vec2 vLayoutUv;
    //         varying vec3 vViewPosition;
    //         varying vec3 vNormal;

    //         varying float vLineIndex;

    //         varying float vLineLettersTotal;
    //         varying float vLineLetterIndex;

    //         varying float vLineWordsTotal;
    //         varying float vLineWordIndex;

    //         varying float vWordIndex;

    //         varying float vLetterIndex;

    //         void main() {
    //             // Output
    //             vec4 mvPosition = vec4(position, 1.0);
    //             mvPosition = modelViewMatrix * mvPosition;
    //             gl_Position = projectionMatrix * mvPosition;

    //             // Varyings
    //             vUv = uv;
    //             vLayoutUv = layoutUv;
    //             vViewPosition = -mvPosition.xyz;
    //             vNormal = normal;

    //             vLineIndex = lineIndex;

    //             vLineLettersTotal = lineLettersTotal;
    //             vLineLetterIndex = lineLetterIndex;

    //             vLineWordsTotal = lineWordsTotal;
    //             vLineWordIndex = lineWordIndex;

    //             vWordIndex = wordIndex;

    //             vLetterIndex = letterIndex;
    //         }
    //     `,
    //         fragmentShader: `
    //         // Varyings
    //         varying vec2 vUv;

    //         // Uniforms: Common
    //         uniform float uOpacity;
    //         uniform float uThreshold;
    //         uniform float uAlphaTest;
    //         uniform vec3 uColor;
    //         uniform sampler2D uMap;

    //         // Uniforms: Strokes
    //         uniform vec3 uStrokeColor;
    //         uniform float uStrokeOutsetWidth;
    //         uniform float uStrokeInsetWidth;

    //         // Utils: Median
    //         float median(float r, float g, float b) {
    //             return max(min(r, g), min(max(r, g), b));
    //         }

    //         void main() {
    //             // Common
    //             // Texture sample
    //             vec3 s = texture2D(uMap, vUv).rgb;

    //             // Signed distance
    //             float sigDist = median(s.r, s.g, s.b) - 0.5;

    //             float afwidth = 1.4142135623730951 / 2.0;

    //             #ifdef IS_SMALL
    //                 float alpha = smoothstep(uThreshold - afwidth, uThreshold + afwidth, sigDist);
    //             #else
    //                 float alpha = clamp(sigDist / fwidth(sigDist) + 0.5, 0.0, 1.0);
    //             #endif

    //             // Strokes
    //             // Outset
    //             float sigDistOutset = sigDist + uStrokeOutsetWidth * 0.5;

    //             // Inset
    //             float sigDistInset = sigDist - uStrokeInsetWidth * 0.5;

    //             #ifdef IS_SMALL
    //                 float outset = smoothstep(uThreshold - afwidth, uThreshold + afwidth, sigDistOutset);
    //                 float inset = 1.0 - smoothstep(uThreshold - afwidth, uThreshold + afwidth, sigDistInset);
    //             #else
    //                 float outset = clamp(sigDistOutset / fwidth(sigDistOutset) + 0.5, 0.0, 1.0);
    //                 float inset = 1.0 - clamp(sigDistInset / fwidth(sigDistInset) + 0.5, 0.0, 1.0);
    //             #endif

    //             // Border
    //             float border = outset * inset;

    //             // Alpha Test
    //             if (alpha < uAlphaTest) discard;

    //             // Output: Common
    //             vec4 filledFragColor = vec4(uColor, uOpacity * alpha);

    //             // Output: Strokes
    //             vec4 strokedFragColor = vec4(uStrokeColor, uOpacity * border);

    //             gl_FragColor = filledFragColor;
    //         }
    //     `,
    //     });

    //     Promise.all([
    //         loadFontAtlas(png),
    //     ]).then(([atlas, font]) => {
    //         this.material3.uniforms.uMap.value = atlas;

    //         Socials.forEach((text, i) => {

    //             const geometry = new MSDFTextGeometry({
    //                 text: text,
    //                 font: fnt,
    //             });


    //             this.mesh4 = new THREE.Mesh(geometry, this.material3);

    //             const point = path.getPoint(i / Socials.length);

    //             let s = .002;
    //             this.mesh4.scale.set(s, -s, s);
    //             // this.mesh4.position.set(i, 0, 1);
    //             // this.mesh4.position.set(point.x - 1, .1, point.y + .3);


    //             this.group.add(this.mesh4)


    //         })

    //     });

    //     function loadFontAtlas(path) {
    //         const promise = new Promise((resolve, reject) => {
    //             const loader = new THREE.TextureLoader();
    //             loader.load(path, resolve);
    //         });

    //         return promise;
    //     }
    // }


    addMesh() {

        this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
            format: THREE.RGBAFormat,
            generateMipmaps: true,
            minFilter: THREE.LinearMipMapLinearFilter,
            encoding: THREE.sRGBEncoding

        })

        this.cubeCamera = new THREE.CubeCamera(.1, 10, this.cubeRenderTarget)
        this.geometry = new THREE.SphereGeometry(2, 32, 32);
        this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            fragmentShader: fragment,
            vertexShader: vertex,
            // wireframe: true,

            uniforms: {
                uTime: {
                    value: 0.0
                }
            }
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.scene.add(this.mesh);


        // small sphere 
        let sphere = new THREE.SphereGeometry(0.5, 32, 32);

        this.material2 = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            fragmentShader: fragment1,
            vertexShader: vertex1,

            uniforms: {
                uTime: { value: 0.0 },
                tCube: { value: 0 },
                mRefractionRatio: { value: 1.02 },
                mFresnelBias: { value: .1 },
                mFresnelScale: { value: 1. },
                mFresnelPower: { value: 2. },
                baseFirst: { value: (120. / 255., 158. / 255., 113. / 255.) },
                accent: { value: (0. / 255., 0. / 255., 0. / 255.) },
                baseSecond: { value: (224. / 255., 148. / 255., 66. / 255.) },
                baseThird: { value: (232. / 255., 201. / 255., 73. / 255.) },
            }

        });
        this.smallSphere = new THREE.Mesh(sphere, this.material2);
        this.group.add(this.smallSphere);

        this.s = mobile ? .56 : 1;


        this.smallSphere.scale.set(this.s, this.s, this.s)
        this.smallSphere.position.z = .8,
            this.smallSphere.position.y = 0.312;
        this.smallSphere.position.x = 0.005;

    }


    mousemove() {

    }

    settings1() {

        this.settings = {
            mRefractionRatio: 1.02,
            mFresnelBias: .1,
            mFresnelScale: 1.,
            mFresnelPower: 2.,

        }

        this.gui = new GUI()



        this.gui.add(this.smallSphere.position, 'y', -1, 4, .001);
        this.gui.add(this.smallSphere.position, 'x', -1, 4, .001);
        this.gui.add(this.smallSphere.position, 'z', -1, 4, .001);



        this.gui.add(this.settings, 'mRefractionRatio', 0, 1, 0.01).onChange(() => {
            this.material2.uniforms.mRefractionRatio.value = this.settings.mRefractionRatio
        })
        this.gui.add(this.settings, 'mFresnelBias', 0, 1, 0.01).onChange(() => {
            this.material2.uniforms.mFresnelBias.value = this.settings.mFresnelBias
        })
        this.gui.add(this.settings, 'mFresnelScale', 0, 1, 0.01).onChange(() => {
            this.material2.uniforms.mFresnelScale.value = this.settings.mFresnelScale
        })
        this.gui.add(this.settings, 'mFresnelPower', 0, 1, 0.01).onChange(() => {
            this.material2.uniforms.mFresnelPower.value = this.settings.mFresnelPower
        })
    }


    initPost() {

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(new RenderPass(this.scene, this.camera));

        const effect1 = new ShaderPass(DotScreenShader);
        effect1.uniforms['scale'].value = 4;
        this.composer.addPass(effect1);
    }

    tick() {
        this.resizeAll();
        this.time += .005;
        this.material.uniforms.uTime.value = this.time;
        // if (this.controls) {
        //     this.controls.update();
        // }
        this.smallSphere.visible = false
        this.cubeCamera.update(this.renderer, this.scene);
        this.smallSphere.visible = true;

        this.smallSphere.scale.set(this.s, this.s, this.s);

        this.material2.uniforms.tCube.value = this.cubeRenderTarget.texture;


        // this.renderer.render(this.scene, this.camera);
        this.composer.render(this.scene, this.camera)
        window.requestAnimationFrame(this.tick.bind(this));
    }
}
new App();


