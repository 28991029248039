varying vec2 vUv;
uniform float utime;

varying float vtime;

void main() {
    vUv = uv;

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    // modelPosition.z += sin(modelPosition.x + 10.0 * vUv.x  ) * .1 ; // Add waves using sin function
    // modelPosition.x +=( sin(modelPosition.x + 10.0 * vUv.x + utime  ) * .01) * 1. ; // Add waves using sin function
    // modelPosition.y +=( sin(modelPosition.x + 10.0 * vUv.x + utime  ) * .1)*1. ; // Add waves using sin function


    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;
}