import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CustomEase from "gsap/CustomEase";


gsap.registerPlugin(ScrollTrigger, CustomEase);



CustomEase.create("favEase", ".785,.135,.15,.86");


function selectAll(elem) {
  return document.querySelectorAll(elem);
}

function select(elem) {
  return document.querySelector(elem);
}

let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

function hamburgerAnimation() {
  let toggle = true;


  let hamburger = select(".hamburger-icon");

  let tl = gsap.timeline({ paused: true });
  tl.to(".ham-1", {
    top: "40%",
    transform: "rotate(10deg)",
  }, "A")
  tl.to(".ham-2", {
    top: "40%",
    transform: "rotate(-10deg)",
  }, "A");
  tl.from("#ham-contents", {
    clipPath: "inset(0% 0% 100% 0%)",
    ease: "favEase",
    duration: 1,
  }, "A");
  tl.from(".video-cont video",{
    scale:1.5,
    clipPath: isMobile? "inset(0 49.999% 0 50%)" :  "inset(0% 0% 100% 0%)",
    ease: "favEase",
    duration: 1.1,
    delay:.2,
  },"A")
  tl.from("#ham-contents ul li a", {
    yPercent: 100,
    stagger: 0.1,
    duration: .5,
    ease:"circ"
  }, "A+=0.5")

  hamburger.addEventListener('click', () => {
    if (toggle === true) {
      tl.play();
    }
    else {
      tl.reverse();
    }
    toggle = !toggle
  });
}

hamburgerAnimation()

