uniform float utime;
uniform vec2 u_mouse;
uniform float u_intensity;

uniform float uOffset;
uniform sampler2D u_texture;
uniform sampler2D u_texture2;

uniform sampler2D uDisplacement;
uniform sampler2D uDisplacement2;
uniform sampler2D uDisplacement3;

uniform float ufrequency;

varying float vfrequency;
varying float vtime;
varying vec2 vUv;

void main() {
    vec2 uv = vUv;
    float vfrequency = ufrequency;
    float wave1 = sin(uv.x * 10.0 + utime * 0.5 + u_mouse.x * 5.0) * u_intensity;
    float wave2 = sin(uv.y * 12.0 + utime * 0.8 + u_mouse.y * 4.0) * u_intensity;
    float wave3 = cos(uv.x * 8.0 + utime * 0.5 + u_mouse.x * 3.0) * u_intensity;
    float wave4 = cos(uv.y * 9.0 + utime * 0.7 + u_mouse.y * 3.5) * u_intensity;


    // uv.y += wave1 + wave2;
    // uv.x += wave3 + wave4;

    vec4 displacementTexture = texture2D(uDisplacement, uv);
    vec4 displacementTexture2 = texture2D(uDisplacement2, uv);
    vec4 displacementTexture3 = texture2D(uDisplacement3, uv);


    float displacementforce1 = displacementTexture3.r * uOffset;
    float uvDisplacedX = uv.x + displacementforce1;
    float uvDisplacedY = uv.y + displacementforce1;

    float displacementforce = displacementTexture2.r * (1. - uOffset);
    float uvDisplacedX2 = uv.x - displacementforce;
    float uvDisplacedY2 = uv.y - displacementforce;

    float displacementforce3 = displacementTexture3.r * (1. - uOffset);
    float uvDisplacedX3 = uv.x - displacementforce3;
    float uvDisplacedY3 = uv.y - displacementforce3;

    vec4 texture1final = texture2D(u_texture, vec2(uvDisplacedX, uvDisplacedY));
    vec4 texture1final2 = texture2D(u_texture2, vec2(uvDisplacedX2, uvDisplacedY2));



    gl_FragColor = texture2D(uDisplacement, uv);
    gl_FragColor = texture2D(u_texture, uv);
    gl_FragColor = (texture1final * (1. - uOffset)) + (texture1final2 * uOffset);

}